import { Modal } from 'Shared/modal';
import { setupUsernameValidation } from 'Shared/username_validator';
import { Auth } from 'Shared/auth';
import { Validation, Geo } from 'Shared/utils';
import { FormManager } from 'Shared/form_manager';
import { Recaptcha } from 'Shared/recaptcha';
import { setupEmailValidation } from 'Shared/email_validator';

// eslint-disable-next-line no-var
export var RegisterPanel = (window.RegisterPanel = new Class({
   Implements: [Events],
   initialize: function () {
      let self = this;
      let inviteData = $('inviteData');

      let formName = 'registerForm';
      let passwordName = 'reg-password';
      let emailInput = $('reg-email');
      let formManager;

      if (inviteData) {
         emailInput.set('value', inviteData.get('data-email'));
      }

      addFormValidation();

      Geo.runEverywhereButEU(function () {
         $$('.js-community-yes-subscription').set('checked', true);
         $$('.js-email-subscriptions').hide();
      });

      let UserLib = window.guide_constants.UserLib;
      let errorFields = {};
      errorFields[UserLib('ERR_PASSWORD_MISMATCH')] = 'reg-password';
      errorFields[UserLib('ERR_PASSWORD_INVALID')] = 'reg-password';
      errorFields[UserLib('ERR_USERNAME_INVALID')] = 'reg-username';
      errorFields[UserLib('ERR_UNIQUE_NAME_INVALID')] = 'reg-unique-username';
      errorFields[UserLib('ERR_EMAIL_INVALID')] = 'reg-email';
      errorFields[UserLib('ERR_EMAIL_EXISTS')] = 'reg-email';
      errorFields[UserLib('ERR_DISALLOWED')] = 'reg-email';
      errorFields[UserLib('ERR_USER_LIMIT')] = 'reg-email';
      errorFields[UserLib('ERR_UNKNOWN')] = 'reg-email';
      errorFields[UserLib('ERR_CAPTCHA_FAIL')] = 'form-captcha-register';

      function addFormValidation() {
         formManager = new FormManager(formName);
         formManager.required('reg-email', 'reg-username', passwordName);

         // Require a password in our specific format.
         formManager.setValidator(passwordName, function (el) {
            if (!Validation.password(el.value)) {
               formManager.error(el, App.registerMessages.password_invalid);
            }
         });

         formManager.setValidator('reg-username', function (el) {
            if (!Validation.username(el.value)) {
               formManager.error(el, App.registerMessages.username_invalid);
            }
         });

         formManager.addSubmitHandler(function () {
            let inviteCode = -1;
            if (inviteData) {
               inviteCode = inviteData.get('data-code');
            }

            const values = {
               login: emailInput.value,
               username: $('reg-username').value,
               uniqueUsername: $('reg-unique-username').value.toLowerCase().trim(),
               password1: $(passwordName).value,
               inviteCode: inviteCode,
            };

            const recaptchaForm = $('form-captcha-register');
            let recaptchaKey = null;
            let recaptchaPlaceholder = null;
            if (recaptchaForm) {
               recaptchaKey = recaptchaForm.get('data-sitekey');
               recaptchaPlaceholder = document.querySelector('.js-captcha-container');
            }
            const registerPromise = Recaptcha.executeInvisible(
               recaptchaPlaceholder,
               recaptchaKey,
               'register'
            ).then(function (recaptchaResponse) {
               return new Promise(function (resolve, reject) {
                  values.captchaResult = recaptchaResponse;
                  Auth.sendRegister(values, function (response) {
                     if (response.loggedIn) {
                        fireGlobal('login.success', response);
                        resolve();
                     } else {
                        fireGlobal('login.failure', response);
                        formManager.showError(errorFields[response.reason], response.message);
                        reject();
                     }
                  });
               });
            });

            LoadingIndicator.withPromise(registerPromise, {
               pendingMessage: _js('Registering your account...'),
               successMessage: _js('Registration successful'),
               failureMessage: _js('Registration unsuccessful'),
            });

            return false;
         });
      }
      // Set up unique username validation from username_validator.js
      setupUsernameValidation(
         $('reg-unique-username'),
         $('valid-name'),
         $('invalid-name'),
         App.usernamePattern,
         formManager
      );

      setupEmailValidation($('reg-email'), formManager);
   },
}));

(function () {
   // Call outside of event listeners in case we aren't in a modal
   // (and the below events won't ever fire)
   onDomReady(initialize);
   let RegisterManager;

   Modal.addEvent('onLoginLoad', function (element, options, data) {
      App.registerMessages = data.registerMessages;
      initialize();
   });

   Modal.addEvent('onLoginUnload', function () {
      RegisterManager = null;
   });

   function initialize() {
      if (RegisterManager) {
         return;
      }
      RegisterManager = new RegisterPanel();
   }
})();
