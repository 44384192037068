import { Utils } from 'Shared/utils';

// eslint-disable-next-line no-var
export var setupEmailValidation = function (emailElement, formManager) {
   formManager.setValidator(emailElement, function (el) {
      let emailSpacesMsg = _js('Emails cannot contain spaces.');
      let email = el.value.toLowerCase();

      if (email.indexOf(' ') > -1) {
         return emailSpacesMsg;
      }
   });

   let verifyThrottler = Utils.throttler(500, function () {
      formManager.validateField(emailElement);
      if (emailElement.retrieve('FormManager:errorMessage')) {
         formManager.showStatusMessage(emailElement);
      }
   });

   emailElement.addEvent('keyup', function (ev) {
      if (ev.key !== 'tab') {
         verifyThrottler.fire();
      }
   });
};
