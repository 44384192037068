/**
 * A status panel that displays a message and (optionally) a loading icon in
 * the middle of the user's viewport. The panel is implemented as a static
 * class, so needn't do anything other than:
 *
 *    StatusPanel.loading('My own loading message'); // default timeout
 *    StatusPanel.loading('My own loading message', 20); // 20s timeout
 *    StatusPanel.deactivate(); // hide, cancel timeout
 *
 * If the timeout expires, the panel will hide and the page will reload.
 */
// eslint-disable-next-line no-var
export var StatusPanel = (window.StatusPanel = new Class({}));
Object.append(StatusPanel, {
   /**
    * Presents the status panel with a custom message ("Loading data..." by
    * default) and a loading icon; takes an optional timeout, which overrides
    * the default.
    *
    * @param message string the message to display
    * @param timeout int the new timeout (in seconds, NOT thousandths)
    */
   loading: function (message, timeout, options) {
      this.__initialize(options);
      this.__message.update(message === null ? _js('Loading data...') : message);
      this.__image.show();
      // Ensure the actual 'showing' happens in the next turn of the event loop
      // so that css transitions work. The elements are added to the DOM in the
      // hidden state and then transitioned to 'shown'.
      setTimeout(
         function () {
            this.__showPanel(timeout, options);
         }.bind(this),
         0
      );
   },

   /**
    * Presents the status panel with a custom message and NO loading icon;
    * otherwise behaves the same as loading().
    */
   notify: function (message, timeout, options) {
      this.__initialize(options);
      this.__message.update(message);
      this.__image.hide();
      this.__showPanel(timeout, options);
   },

   /**
    * Deactivates the status panel, hiding it and canceling the timeout.
    * @param noDelay removes delay from deactivating screen
    */
   deactivate: function (noDelay) {
      // cancel the timeout that would otherwise reload the page
      this.__cancelTimeout();

      // hide the panel after minShowTime seconds, or immediately if that much
      // time has already passed
      let elapsed = (Date.now() - this.__showStart) / 1000;
      let delay = noDelay ? 0 : Math.max(this.__minDisplay - elapsed, 0);
      this.__hidePanel.delay(delay * 1000, this);
   },

   /**
    * Inserts the panel into the page layout, sets appropriate styling, and
    * conditionally adds an IFrame to stop UI elements from showing through the
    * transparent background in IE. SHOULD NOT be called directly, as it will
    * be called from loading() or notify(); only initializes once.
    */
   __initialize: function (options) {
      // only do initialization once; if we already have a panel then there's
      // nothing to do
      if (this.__panel) {
         return;
      }

      this.__defaultTimeout = 10000; // milliseconds
      this.__minDisplay = 1; // seconds
      this.__timeouts = [];
      this.__reload = true;

      // the message text (can be changed)
      this.__message = new Element('p', {
         'data-testid': 'status-loading',
      }).update(_js('Loading data...'));

      // create the panel, and add the loading image and text
      this.__panel = new Element('div', {
         id: 'statusPanel',
      });

      // the loading throbber animation
      if ($$('html').hasClass('.lt-ie10')) {
         this.__image = new Element('div', {
            styles: {
               width: 100,
               height: 100,
               margin: '0px auto',
               backgroundImage:
                  'url(' + window.shared_constants.BaseURI('SITE_IMAGE_CART_LOADING') + ')',
               backgroundRepeat: 'no-repeat',
               display: 'none',
            },
         });
      } else {
         this.__image = new Element('div', {
            class: 'throbber',
         });
      }

      this.__panel.adopt(this.__image);
      this.__panel.adopt(this.__message);
      this.__panel.addEvent(
         'click',
         function () {
            this.__panel.hide();
         }.bind(this)
      );
      this.__panel.inject(document.body);
      this.__shim = new IframeShim(this.__panel, {
         display: true,
      });
   },

   /**
    * Centers and displays the panel with a timeout of `timeout` seconds.
    *
    * @param timeout the timeout in seconds
    */
   __showPanel: function (timeout, options) {
      this.__shim.show();

      this.__panel.addClass('show-panel');
      this.__setTimeout(timeout, options);
      this.__showStart = Date.now();
   },

   __hidePanel: function () {
      if (this.__shim) {
         this.__shim.hide();
      }

      if (this.__panel) {
         this.__panel.removeClass('show-panel');
      }
   },

   /**
    * Sets a timeout on the panel, either using the passed in timeout, or if
    * no default is supplied, the default.
    *
    * @param timeout int the timeout in seconds
    */
   __setTimeout: function (timeout, options) {
      if (timeout === null) {
         timeout = this.__defaultTimeout;
      } else {
         timeout *= 1000;
      }

      this.__timeouts.push(setTimeout(this.__timeoutExpired.bind(this, options), timeout));
   },

   /**
    * Cancels the latest timeout.
    */
   __cancelTimeout: function () {
      if (this.__timeouts) {
         clearTimeout(this.__timeouts.pop());
      }
   },

   /**
    * Handles a timeout by hiding the loading image (if it's visible), and
    * reloads the page after two extra seconds of waiting.
    */
   __timeoutExpired: function (options) {
      if (options && options.reload !== undefined && !options.reload) {
         if (options.keepOpen === undefined || !options.keepOpen) {
            this.__hidePanel();
         }
         return;
      }

      this.__image.hide();

      setTimeout(
         function () {
            this.deactivate();
            document.location.reload();
         }.bind(this),
         2000
      );
   },
});
