import { CSRF } from 'Shared/csrf';
import { Auth } from 'Shared/auth';
import { FormManager } from 'Shared/form_manager';
import { StatusPanel } from 'Shared/status_panel';
import { Modal } from 'Shared/modal';
import { Recaptcha } from 'Shared/recaptcha';
import { setupEmailValidation } from 'Shared/email_validator';

export { LoginPanel };
window.LoginPanel = LoginPanel;
function LoginPanel() {
   let emailInputs = {
         showLogin: $(App.featureDevNoEmailLogin ? 'login-id' : 'email'),
         showRegister: $('reg-email'),
         showPasswordReset: $('reset-email'),
      },
      _this = this;

   this.focusEmail = function () {
      let input = getEmailInput();
      if (input) {
         // We delay this till the next turn of the event loop when the UI has
         // been updated (often the panel is shown, then focus is called)
         // othwise it seems to have no affect.
         (function () {
            try {
               // This can fail on old versions of IE because they throw an error
               // if this is called when the controls are not visible yet.
               input.focus();
               // eslint-disable-next-line no-empty
            } catch (e) {}
         }.delay(0));
      }
   };

   this.getEmail = function () {
      let input = getEmailInput();
      return input && input.value;
   };

   this.setEmails = function (email) {
      for (let cssClass in emailInputs) {
         emailInputs[cssClass].set('value', email);
      }
   };

   function getEmailInput() {
      let loginContainer = $('login');
      for (let cssClass in emailInputs) {
         if (loginContainer.hasClass(cssClass)) {
            return emailInputs[cssClass];
         }
      }
   }

   this.focusFirst = function () {
      // If we're on the registration page focus the username, otherwise focus
      // whatever email option is available.
      if ($('reg-username') && $('reg-username').isVisible()) {
         $('reg-username').focus();
      } else {
         _this.focusEmail();
      }
   };

   this.showPanel = function (cssClass) {
      let email = _this.getEmail();
      let login = $('login');
      cssClass = cssClass || login.get('data-initial');
      ['showLogin', 'showRegister', 'showPasswordReset'].each(login.removeClass.bind(login));
      login.addClass(cssClass);
      _this.setEmails(email);
      _this.focusFirst();
      Modal.fireEvent('showPanel:' + cssClass);
   };

   this.showPanel();
   this.focusFirst();
}

// eslint-disable-next-line no-var
export var ForgotPasswordForm = (window.ForgotPasswordForm = new Class({
   initialize: function () {
      clickSafe('resetPasswordBtn', function (event) {
         event.stop();
         function showMessage(response) {
            StatusPanel.notify(response, 5, { reload: false });
         }
         new Request.API_2_0('users/reset_password', {
            method: 'POST',
            onSuccess: function (response) {
               showMessage(response.message);
            },
            onFailure: function (xhr, response) {
               showMessage(response);
            },
         }).send({ email: $('reset-email').value });
      });
   },
}));

// eslint-disable-next-line no-var
export var LoginForm = (window.LoginForm = new Class({
   Implements: [Events],
   initialize: function () {
      let self = this;
      let inviteData = $('inviteData');
      let rootElement = $('login');
      this.formName = 'loginForm';
      this.emailName = 'email';
      this.loginIdName = 'login-id';
      this.regEmailName = 'reg-email';
      this.passwordName = 'password';
      this.registerForm = 'registerForm';
      this.formManager = new FormManager(this.formName);
      this.loginNameManager = App.featureDevNoEmailLogin ? this.loginIdName : this.emailName;
      this.formManager.required(this.loginNameManager, this.passwordName);

      if (!App.featureDevNoEmailLogin) {
         setupEmailValidation($(this.loginNameManager), this.formManager);
      }
      if (inviteData) {
         $(this.loginNameManager).set('value', inviteData.get('data-email'));
      }

      // If we're in IE don't show the fa-eye icon for the password.
      if (Browser.name === 'ie' || Browser.name === 'safari') {
         $$('.show-password').hide();
      } else {
         $$('.show-password').addEvent('click', function (event, target) {
            self.togglePassword(event);
         });
      }

      this.formManager.addSubmitHandler(
         function () {
            if (!this.loginVisible()) {
               return true;
            }
            const login = $(this.loginNameManager);
            const password = $(this.passwordName);

            const recaptchaForm = $('form-captcha-login');
            let recaptchaKey = null;
            let recaptchaPlaceholder = null;
            if (recaptchaForm) {
               recaptchaKey = recaptchaForm.get('data-sitekey');
               recaptchaPlaceholder = document.querySelector('.js-captcha-container');
            }

            const loginPromise = Recaptcha.executeInvisible(
               recaptchaPlaceholder,
               recaptchaKey,
               'login'
            )
               .then(recaptchaResponse => {
                  return Auth.sendLogin({
                     login: login.value,
                     password: password.value,
                     captchaResult: recaptchaResponse,
                     ajax: true,
                     handler: this.loginHandler.bind(this),
                  });
               })
               .then(result => {
                  if (result.passwordResetHash && result.userSetupRequired) {
                     window.location =
                        window.shared_constants.GuideURI('TAG_USER_SETUP') + `/${result.userid}`;
                     return;
                  }

                  if (result.passwordResetHash) {
                     window.location =
                        window.shared_constants.GuideURI('TAG_RESET_PASSWORD') +
                        `/${result.userid}/${result.passwordResetHash}`;
                     return;
                  }

                  if (result.reason === 'unverifiedEmail') {
                     window.location =
                        window.shared_constants.GuideURI('USER_SEND_VERIFICATION') +
                        `/${result.userid}`;
                     return;
                  }

                  if (!result.loggedIn) {
                     return Promise.reject();
                  }
               });

            LoadingIndicator.withPromise(loginPromise, {
               pendingMessage: App.featureDevNoEmailLogin
                  ? _js('Checking your login id and password...')
                  : _js('Checking your email and password...'),
               successMessage: _js('Login successful'),
               failureMessage: _js('Login unsuccessful'),
            });

            return false;
         }.bind(this)
      );

      $$('.formToggle').addEvent('click', function (event) {
         event.preventDefault();
         LoginPanelManager.showPanel(event.target.get('data-action'));
      });

      let googleLoginContainer = $('googleLoginContainer');
      if (googleLoginContainer) {
         // Setup google.
         window.onGoogleLibraryLoad = function () {
            const width = Math.round(googleLoginContainer.getBoundingClientRect().width);
            google.accounts.id.initialize({
               client_id: App.googleOAuth2Clientid,
               callback: handleLogInWithGoogle,
            });
            google.accounts.id.renderButton(googleLoginContainer, {
               type: 'standard',
               theme: 'filled_black',
               width: width,
               locale: App.locale,
            });
         };

         let loadGoogleLibrary = function () {
            if (typeof google !== 'undefined') {
               return;
            }
            let po = document.createElement('script');
            po.type = 'text/javascript';
            po.async = true;
            po.src = 'https://accounts.google.com/gsi/client';
            let s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(po, s);
         };

         let handleLogInWithGoogle = function (googleLoginData) {
            Auth.sendLogin({
               googleLoginData: googleLoginData,
               ajax: true,
               handler: this.loginHandler.bind(this),
            });
         }.bind(this);

         window.handleLogInWithGoogle = handleLogInWithGoogle;

         loadGoogleLibrary();
      }
   },

   loginVisible: function () {
      return $('login').hasClass('showLogin');
   },

   loginHandler: function (response) {
      Auth.clearCookies();
      StatusPanel.deactivate(/* noDelay */ true);

      if (response.loggedIn) {
         fireGlobal('login.success', response);
      } else {
         // Where the error message should be shown, (either registration form
         // or the login form)
         let errorElement =
            $(this.registerForm).getStyle('display') === 'none'
               ? $(this.loginNameManager)
               : $(this.regEmailName);

         switch (response.reason) {
            case 'email':
               App.featureDevNoEmailLogin
                  ? this.formManager.showError(errorElement, App.loginMessages.invalid_login_id)
                  : this.formManager.showError(errorElement, App.loginMessages.invalid_email);
               break;
            case 'password':
               App.featureDevNoEmailLogin
                  ? this.formManager.showError(
                       errorElement,
                       App.loginMessages.invalid_password_login_id
                    )
                  : this.formManager.showError(errorElement, App.loginMessages.invalid_password);
               break;
            case 'banned':
               this.formManager.showError(errorElement, App.loginMessages.banned);
               break;
            case 'captcha':
               this.formManager.showError(Recaptcha.container(), App.loginMessages.captcha);
               break;
            case 'tooManyFailures': {
               const expiration = parseInt(response.lockedOut, 10);
               const xMessage =
                  expiration == 1
                     ? _js('Click "Forgot?" or wait one minute to try again.')
                     : _js('Click "Forgot?" or wait %1 minutes to try again.');
               const message = App.loginMessages.tooManyFailures + ' ' + xMessage;

               this.formManager.showError(errorElement, message.replace('%1', expiration));
               break;
            }
            case 'tooManySuccesses': {
               const expiration = parseInt(response.lockedOut, 10);
               const xMessage =
                  expiration == 1
                     ? _js('Wait one minute to try again.')
                     : _js('Wait %1 minutes to try again.');
               const message = App.loginMessages.tooManySuccesses + ' ' + xMessage;
               this.formManager.showError(errorElement, message.replace('%1', expiration));
               break;
            }
            case 'disabledUser':
               this.formManager.showError(errorElement, App.loginMessages.disabledUser);
               break;
            case 'insecurePassword':
               this.formManager.showError(errorElement, App.loginMessages.insecurePassword);
               break;
            case 'facebook': {
               const message = _js('Please login using the "Sign in with %1" button instead.');
               this.formManager.showError(
                  errorElement,
                  message.replace('%1', response.reason.capitalize())
               );
               break;
            }
            case 'OAuth2_GOOGLE': {
               const message = _js('Please login using the "Sign in with Google" button instead.');
               this.formManager.showError(errorElement, message);
               break;
            }
            case 'csrf':
               this.formManager.showError(errorElement, App.loginMessages.csrf);
               break;
            case 'adminOnlyEntrypoint':
               this.formManager.showError(errorElement, App.loginMessages.adminOnlyEntrypoint);
               break;
            default:
               this.formManager.showError(errorElement, response.reason);
         }
         fireGlobal('login.failure', response);
      }
   },

   handleFacebookLoggedIn: function (fbResponse) {
      Auth.clearCookies();

      if (fbResponse && !fbResponse.authResponse) {
         this.formManager.showError($(this.emailName), _js('Unable to complete Facebook login.'));
         return;
      }

      Auth.sendFacebookLogin(this.loginHandler.bind(this));
   },

   setupView: function () {
      this.formManager.focus();
   },

   togglePassword: function () {
      let passwordInput = $('reg-password');
      if (passwordInput.get('type') === 'password') {
         passwordInput.set('type', 'text');
         $('js-password-icon').getElement('i').removeClass('fa-eye-slash').addClass('fa-eye');
      } else {
         passwordInput.set('type', 'password');
         $('js-password-icon').getElement('i').removeClass('fa-eye').addClass('fa-eye-slash');
      }
   },
}));

(function () {
   let modalOptions;

   // Call outside of event listeners in case we aren't in a modal
   // (and the below events won't ever fire)
   onDomReady(initialize);

   listenGlobal('login.success', function success(response) {
      if (!modalOptions) {
         return;
      }

      if (modalOptions.close) {
         Modal.close();
      }

      if (modalOptions.onAuthorize) {
         setTimeout(modalOptions.onAuthorize.bind(modalOptions, response), Modal.animationDuration);
      }

      if (modalOptions.reload) {
         return window.location.reload();
      }
   });

   Modal.addEvent('onLoginLoad', function (element, options, data) {
      modalOptions = options;
      App.loginMessages = data.loginMessages;
      initialize();
   });

   Modal.addEvent('onLoginUnload', function () {
      window.LoginPanelManager = null;
      window.LoginManager = null;
      window.ForgotPasswordManager = null;
   });

   function initialize() {
      if (window.LoginManager) {
         LoginPanelManager.showPanel();
         return;
      }
      window.LoginPanelManager = new LoginPanel();
      window.LoginManager = new LoginForm();
      window.ForgotPasswordManager = new ForgotPasswordForm();
   }
})();
