(function () {
   let fbLoginStatus;
   let fbPrivileges;
   let began;
   let complete;
   let callbacks = [];

   function triggerCallbacks(loggedIn) {
      fbPrivileges = loggedIn;
      complete = true;
      callbacks.each(function (callback) {
         callback(loggedIn);
      });
      callbacks = [];
   }

   // eslint-disable-next-line no-var
   var FacebookHelper = (window.FacebookHelper = {
      initialize: function (callback) {
         if (callback) {
            callbacks.push(callback);
         }

         if (began) {
            if (complete) {
               callback(fbPrivileges);
            }
            return;
         }

         began = true;

         window.fbAsyncInit = function () {
            FB.init({
               appId: App.facebookAppId,
               cookie: true, // enable cookies to allow the server to access the session
               version: 'v2.12',
               status: true, // get login state
            });
            FacebookHelper.checkLoginState();
         };

         Asset.javascript('//connect.facebook.net/en_US/sdk.js', {
            id: 'facebook-jssdk',
         });
      },

      share: function (object, callback) {
         object = object || {
            method: 'share',
            href: window.location.href,
         };
         callback = callback || function () {};
         FB.ui(object, callback);
      },

      /**
       * Trying to wait for the "login status" on the click event ends up being
       * kinda difficult to do w/o callbacks. Instead we will just set a global
       * "facebook login status" when we load facebook resources and use that.
       */
      checkLoginState: function () {
         // Figure out if the current user is logged in.
         FB.getLoginStatus(function (loginResponse) {
            fbLoginStatus = loginResponse.status;
            if (fbLoginStatus == 'connected') {
               // Grab the privileges they've authorized.
               FB.api('/me/permissions', function (response) {
                  if (!response.error) {
                     fbPrivileges = response.data;
                     triggerCallbacks(fbPrivileges);
                  } else {
                     triggerCallbacks(false);
                  }
               });
            } else {
               triggerCallbacks(false);
            }
         });
      },

      performLogin: function (scopes, onLoggedIn) {
         FacebookHelper.initialize(function (fbPrivileges) {
            let newPermissions = false;
            let fbLoggedIn = true;

            let fbLoginParams = { scope: scopes };

            if (fbLoginStatus == 'connected') {
               // If any fbPrivileges are authorized, veify we don't need new ones.
               if (fbPrivileges) {
                  newPermissions = scopes.split(',').some(function (asked) {
                     return !fbPrivileges.some(function (p) {
                        return p.permission == asked && p.status == 'granted';
                     });
                  });
                  // Ask for authorization again if we didn't find any privileges.
               } else {
                  newPermissions = true;
               }
               // Ask for authorization again if "not_authorized".
            } else if (fbLoginStatus == 'not_authorized') {
               newPermissions = true;
               // Otherwise just call fb login.
            } else {
               fbLoggedIn = false;
            }

            if (newPermissions) {
               fbLoginParams.auth_type = 'rerequest';
            }

            if (!fbLoggedIn || newPermissions) {
               FB.login(onLoggedIn, fbLoginParams);
            } else {
               onLoggedIn({ authResponse: 'loggedIn' });
            }
         });
      },
   });

   onDomReady(function () {
      let scopes;
      let fbLoginBtn = $('facebookLogin');

      if (fbLoginBtn) {
         scopes = fbLoginBtn.get('data-fb-scope');

         // Start the process
         FacebookHelper.initialize(function () {
            fbLoginBtn.removeClass('disabled');
         });

         fbLoginBtn.addEvent('click', function (ev) {
            ev.stop();
            let target = $(ev.target);
            if (target.hasClass('disabled')) {
               return;
            }
            target.addClass('disabled');

            FacebookHelper.performLogin(scopes, function (response) {
               fbLoginBtn.removeClass('disabled');
               LoginManager.handleFacebookLoggedIn(response);
            });
         });
      }
   });
})();
